import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  const ResponsePage = lazy(() => import("./pages/ResponsePage"));
  const LandingPage = lazy(() => import("./pages/LandingPage"));
  const PageNotFound = lazy(() => import("./pages/404"));
  const SignUp = lazy(() => import("./pages/SignupPage"));
  return (
    <Suspense fallback={<p></p>}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/response" element={<ResponsePage />} />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
